import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './sass/style.scss';
// import '@node_modules/'

import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";

createApp(App)
.use(router)
.use(VueSvgInlinePlugin)
.mount('#app');