<template>
    <div class="home">

            <div class="home__leader-bg">
                <Atropos class="my-atropos" :shadow="false" :active-offset="0" :rotateXMax="5" :rotateYMax="5">
                    <div class="home__leader">
                        <div class="home__leader-wrapper">
                            <div class="home__left" data-atropos-offset="3">
                                <div class="home__logo">
                                    <img v-svg-inline src="@/assets/logo2.svg" />
                                </div>
                                <div class="home__leader-copy">
                                    <p class="intro">9m x 15m Photography, Video and Virtual Production space
                                    in Plymouth, Devon.
                                    </p>
                                    <p class="contact">
                                        0845 862 0522<br /><span class="psname psname--white">hello@<span class="plymouth">plymouth.</span><span class="studio">studio</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="home__right" data-atropos-offset="0">
                                <img src="@/assets/unit.png" />
                            </div>
                        </div>

                    </div>
                </Atropos>
            </div>

        <div class="home__about">
            <div class="body-row">
                <h2 class="block-header">Hello!</h2>
                <p><span class="psname"><span class="plymouth">Plymouth.</span><span class="studio">studio</span></span> is a generously sized, accessible photographic and video studio, with facility for large green-screen virtual productions, supported by our Digital Production team.</p>
                <p>The studio is supplied by fully renewable electrical energy sources, and is supported by a 6KW Photovaltic array.</p>
                <p>A selection of equipment is available to hire, including still and video cameras, lighting, power distribution boards, coloramas, light deflectors and more.</p>
                <p>The space is available to hire by individuals or organisations, in either half-day or full-day blocks.</p>
                <p class="contact">
                    0845 862 0522<br /><span class="psname">hello@<span class="plymouth">plymouth.</span><span class="studio">studio</span></span>
                </p>
            </div>
        </div>

        <div class="home__facilities">
            <div class="body-row">
                <h2 class="home__facilities-header block-header">Facilities</h2>
                <ul class="home__facilities-list">
                    <li>Free reserved parking</li>
                    <li>6m x 10m Mid Studio (60 m²)</li>
                    <li>9m x 15m Full Studio (135 m²)</li>
                    <li>6m Height (minimum)</li>
                    <li>Kitchen</li>
                    <li>3m x 6m drive-in roller-door</li>
                    <li>32-amp commando sockets</li>
                    <li>8x 240v outlets + cable trays</li>
                    <li>3-phase power (on request)</li>
                    <li>Toilet / shower / wetroom</li>
                </ul>
            </div>
        </div>

        <div class="body-row home__location">
            <div class="home__location-image">
                <img src="@/assets/location-map.jpg" />
            </div>
            <div class="home__location-copy">
                <h2 class="block-header">Location</h2>
                <p class="intro">Just 2 minutes from the A38 at Langage Science Park, Plympton, Plymouth.</p>

                <address>Unit 11, Hearder Court<br />
                Beechwood Way<br />
                Plymouth<br />
                PL7 5HH
                </address>
            </div>
        </div>

        <div class="body-row home__gallery">
            <h2 class="block-header">Gallery</h2>
            <div class="home__gallery-wrapper">
                <img src="@/assets/gallery/cyc.jpg" />
                <img src="@/assets/gallery/tom-scan-comparison.jpg" />
                <img src="@/assets/gallery/wide.jpg" />
                <img src="@/assets/gallery/set-face-mocap.jpg" />
                <img src="@/assets/gallery/studio-front.jpg" />
                <img src="@/assets/gallery/set-360.jpg" />
            </div>
        </div>


        <footer class="home__footer">
            <div class="body-row">
                <div class="home__footer-logo">
                    <img v-svg-inline src="@/assets/logo-inverted-nostrap.svg" />
                </div>
                <div class="home__footer-details">
                    <span class="tab">Email:</span> hello@plymouth.studio<br />
                    <span class="tab">Phone:</span> 0845 862 0522<br />
                    <span class="tab">Address:</span> 11 Hearder Court, Plymouth, PL7 5HH<br />
                    <span class="tab">Socials:</span> <a target="_new" href="http://fb.me/plymouth.studio"><span class="st-icon-facebook st-shape-rounded3">Facebook</span></a> <a target="_new" href="https://www.instagram.com/plymouth.studio/"><span class="st-icon-instagram st-shape-rounded3">Instagram</span></a>

                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Atropos from 'atropos/vue/atropos-vue.esm';

@Options({
    props: {
    },
    components: {
        'Atropos': Atropos
    }
})
export default class Home extends Vue {


}
</script>