
import { Options, Vue } from 'vue-class-component';

import Atropos from 'atropos/vue/atropos-vue.esm';

@Options({
    props: {
    },
    components: {
        'Atropos': Atropos
    }
})
export default class Home extends Vue {


}
